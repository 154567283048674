import React from "react";
import styled from "styled-components";
import { useMixpanel } from "../context/MixpanelContext";
import { isBrowser } from "../utils/browserUtils";
import {SubTitle, Text} from "../components/core/commonExports"

import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import useHindiLangFont from "../hooks/useHindiLangFont";
import { OnDesktop, OnMobile } from "./DesktopMobileComps";
import checkOfficeHours from "../utils/checkOfficeHours";

const Button = loadable(() => import("./core"), {
  resolveComponent: (components) => components.Button
})
const Section = loadable(() => import("./core"), {
  resolveComponent: (components) => components.Section
})
const SectionWrapper = loadable(() => import("./core"), {
  resolveComponent: (components) => components.SectionWrapper
})
const Title = loadable(() => import("./core"), {
  resolveComponent: (components) => components.Title
})

export default function HelpSection({ reverse = true, mlp=false }) {
  const {hindiBold} = useHindiLangFont()
  const {t} = useTranslation(['helpSection', 'common'])
  const mixpanel = useMixpanel();
  const isOfficeHours = checkOfficeHours()

  let mxEventName = "";
  let pathname = "";

  if (isBrowser) {
    pathname = window.location.pathname;
  }

  switch (pathname) {
    case "/fire":
      mxEventName = "Clicked-Bottom - 'WA Chat' - Fire LP";
      break;
    case "/fire/bsus":
      mxEventName = "Clicked 'WA Chat' - BSUS LP";
      break;
    case "/fire/blus":
      mxEventName = "Clicked 'WA Chat' - BLUS LP";
      break;
    case "/fire/sfsp":
      mxEventName = "Clicked 'WA Chat' - SFSP LP";
      break;
    case "/liability":
      mxEventName = "Clicked 'WA Chat' - Liability LP";
      break;
    case "/liability/directorsandofficers":
      mxEventName = "Clicked 'WA Chat' - D&O LP";
      break;
    case "/liability/errorsandomissions":
      mxEventName = "Clicked 'WA Chat' - E&O LP";
      break;
    case "/liability/workmen_compensation":
      mxEventName = "Clicked 'WA Chat' - WC LP";
      break;
    case "/liability/public_liability":
      mxEventName = "Clicked 'WA Chat' - PL LP";
      break;
    default:
      mxEventName = "Clicked 'WA Chat'- MLP";
  }

  return (
    <Section background="#FFF7F1" height="600px">
      <SectionWrapper>
        <Container reverse={reverse}>
          <div className="help-icon">
            <StaticImage
              src="../assets/images/help.webp"
              alt="help"
            />
          </div>
          <HeadingContainer>
            <SubTitle style={{ marginBottom: "40px", textTransform: mlp ? "uppercase" : "" }} className={hindiBold}>
              <Trans i18nKey="ChatWithUs">
                Have a question? <br/> We are here to help
              </Trans>
              
            </SubTitle>
            <OnMobile>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/919769161630?text=Hey"
              >
                <Button
                  label={t('CTALabels.SayHiOnWA')}
                  wide
                  type="whatsapp"
                  onClick={() => mixpanel.track(mxEventName)}
                  className={hindiBold}
                  style={{ width: "min(365px, 100%)", minWidth: "280px"}}
                />
              </a>
              {isOfficeHours && <>
                <div style={{ width: "min(365px, 100%)"}}>
                  <Text fontWeight="bold" style={{width: "fit-content", margin: "8px auto"}}>OR</Text>
                </div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="tel:+919076277062"
                >
                  <CallusButton
                    label={t('CTALabels.CallUs')}
                    wide
                    uppercaseLabel={false}
                    // onClick={() => mixpanel.track(mxEventName)}
                    className={hindiBold}
                  />
                </a>
              </>}
            </OnMobile>
            <OnDesktop>
              <Button
                label={t('CTALabels.SayHiToCustomerSupport')}
                wide
                type="chatbot"
                onClick={() => document.querySelector('#ymDivBar')?.click()}
                className={hindiBold}
              />
            </OnDesktop>
          </HeadingContainer>
        </Container>
      </SectionWrapper>
    </Section>
  );
}

const HeadingContainer = styled.div`
  margin-left: 80px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .help-icon {
    margin-right: 80px;
    width: min(450px, 100%);

    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin: 2.5rem 0;
      width: 250px;
    }
  }
`;

const CallusButton = styled(Button)`
  --green-color: rgba(117, 204, 31, 1);
  border: 1px solid var(--green-color); 
  color: var(--green-color); 
  background: #fff; 
  width: min(365px, 100%); 
  min-width: 280px;
  box-shadow: none;
`
